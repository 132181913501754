var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import widgets from './core/Widgets/widgets';
import './webcomponent';
var widgetList = __assign({}, widgets);
var infos = Object.keys(widgetList).map(function (tagName) { return ({
    name: tagName,
    constructor: function () { return /** @class */ (function (_super) {
        __extends(ReactElement, _super);
        function ReactElement() {
            var _this = _super.call(this) || this;
            // prepare dom
            _this.wrapper = document.createElement('div', { class: 'app' });
            _this.wrapper.style.boxSizing = 'border-box'; // for grid system
            _this.head = document.createElement('head');
            var widgetInfo = widgetList[tagName];
            _this.eventCategory = "".concat(_this.id, ":").concat(tagName, ":");
            if (!widgetInfo.noshadow) {
                _this.attachShadow({ mode: 'open' });
                _this.shadowRoot.appendChild(_this.head);
                _this.shadowRoot.appendChild(_this.wrapper);
            }
            widgetList[tagName].properties.map(function (property) {
                if (!property.type || property.type === Function) {
                    return property;
                }
                Object.defineProperty(_this, property.name, {
                    get: function () {
                        var attrVal = _this.getAttribute(property.name);
                        switch (property.type) {
                            case Number:
                                if (attrVal) {
                                    var attrNumVal = Number(attrVal);
                                    return Number.isNaN(attrNumVal) ? undefined : attrNumVal;
                                }
                                return undefined;
                            case Boolean:
                                return _this.hasAttribute(property.name);
                            case Object:
                            case Array:
                                try {
                                    return JSON.parse(_this.getAttribute(property.name));
                                }
                                catch (_a) {
                                    return null;
                                }
                            case String:
                            default:
                                return _this.getAttribute(property.name);
                        }
                    },
                    set: function (newValue) {
                        switch (property.type) {
                            case Boolean:
                                if (newValue) {
                                    _this.setAttribute(property.name, property.name);
                                }
                                else {
                                    _this.removeAttribute(property.name);
                                }
                                break;
                            case Object:
                            case Array:
                                _this.setAttribute(property.name, JSON.stringify(newValue));
                                break;
                            case Number:
                            case String:
                            default:
                                if (newValue) {
                                    _this.setAttribute(property.name, newValue);
                                }
                                else {
                                    _this.removeAttribute(property.name);
                                }
                                break;
                        }
                    },
                });
                return property;
            });
            return _this;
        }
        ReactElement.prototype.attributeChangedCallback = function (name, oldValue, newValue) {
            if (this.ref && this.ref.current) {
                if (newValue !== oldValue) {
                    this.ref.current[name] = this[name];
                }
            }
        };
        Object.defineProperty(ReactElement, "observedAttributes", {
            get: function () {
                return widgetList[tagName].properties
                    .map(function (p) { return (p.watch ? p.name : null); })
                    .filter(function (p) { return p; });
            },
            enumerable: false,
            configurable: true
        });
        ReactElement.prototype.applyStyles = function (name) {
            var _this = this;
            var preloadLinks = document.head.querySelectorAll('[data-dui]');
            preloadLinks.forEach(function (preloadLink) {
                if (preloadLink.href.indexOf("".concat(name, ".css")) < 0)
                    return;
                var link = document.createElement('link');
                link.href = preloadLink.href;
                link.rel = 'stylesheet';
                link.type = 'text/css';
                _this.head.append(link);
            });
        };
        ReactElement.prototype.connectedCallback = function () {
            return __awaiter(this, void 0, void 0, function () {
                var widgetInfo, utils, parseJson, React, ReactDom, Component;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            widgetInfo = widgetList[tagName];
                            if (widgetInfo.grid) {
                                // const styleSheet = document.createElement('style');
                                import(/* webpackChunkName: "dui-bootstrap-grid" */ 'bootstrap/scss/bootstrap-grid.scss').then(function () {
                                    _this.applyStyles('dui-bootstrap-grid');
                                });
                                // console.log('grid==>', grid.default[0][1]);
                                // //@ts-ignore
                                // styleSheet.innerHTML = grid.default[0][1];
                                // this.head.appendChild(styleSheet);
                            }
                            if (widgetInfo.noshadow) {
                                this.appendChild(this.head);
                                this.appendChild(this.wrapper);
                            }
                            // transform data
                            this.dataNode = this.querySelector('data');
                            if (!this.dataNode) return [3 /*break*/, 4];
                            if (!(this.dataNode.getAttribute('is') === 'json')) return [3 /*break*/, 1];
                            try {
                                this.props = JSON.parse(this.dataNode.innerHTML);
                            }
                            catch (e) {
                                this.props = {};
                                console.error(e);
                            }
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, import('./utils')];
                        case 2:
                            utils = _a.sent();
                            parseJson = utils.parseJson;
                            this.props = parseJson(this.dataNode, true, '');
                            _a.label = 3;
                        case 3:
                            this.removeChild(this.dataNode);
                            _a.label = 4;
                        case 4: return [4 /*yield*/, import('react')];
                        case 5:
                            React = _a.sent();
                            return [4 /*yield*/, import('react-dom')];
                        case 6:
                            ReactDom = _a.sent();
                            this.ref = React.createRef();
                            if (widgetList[tagName].loader) {
                                widgetList[tagName].loader().then(function (widget) {
                                    _this.applyStyles(tagName);
                                    ReactDom.render(_jsx(widget.default, __assign({ tagName: tagName, ref: _this.ref }, _this.props, { dom: _this, properties: widgetList[tagName].properties })), _this.wrapper);
                                });
                            }
                            if (widgetList[tagName].component) {
                                Component = widgetList[tagName].component;
                                ReactDom.render(_jsx(Component, __assign({ tagName: tagName, ref: this.ref }, this.props, { dom: this, properties: widgetList[tagName].properties })), this.wrapper);
                            }
                            if (window.AnalyticsSDK && !widgetInfo.connected) {
                                widgetInfo.connected = true;
                                window.AnalyticsSDK.sendGaEvent({
                                    eventCategory: "".concat(tagName, ":Connected"),
                                    eventAction: 'init',
                                    eventLabel: { type: 'dui-tracking' },
                                    location: window.location.href,
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        return ReactElement;
    }(HTMLElement)); },
}); });
window.DUIWidgetList = widgetList;
var load = function () { return infos
    .forEach(function (_a) {
    var name = _a.name, constructor = _a.constructor;
    return window.WebComponents.waitFor(function () { return customElements.define(name, constructor()); });
}); };
export default load;
